<template>
	<div class="card py-2">
		<!-- Statuses -->
		<div class="statuses px-2 mb-1">
			<ul class="nav nav-tabs border-bottom">
				<li class="nav-item">
					<button class="nav-link" :class="{'active': categoryQuery === null}" @click="setCategoryQuery(null)">All
					</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{'active': categoryQuery === 'due'}" @click="setCategoryQuery('due')">Due
					</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{'active': categoryQuery === 'not_due'}"
									@click="setCategoryQuery('not_due')">Not Due
					</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{'active': categoryQuery === 'completed'}"
									@click="setCategoryQuery('completed')">Completed
					</button>
				</li>
			</ul>
		</div>
		<!-- / Statuses -->

		<!-- Actions -->
		<div class="actions row align-items-center mb-2 px-2">
			<div class="col-md-6">
				<label>Show</label>
				<v-select
					v-model="perPage"
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:options="perPageOptions"
					:clearable="false"
					class="per-page-selector d-inline-block mx-50"
				/>
				<label>entries</label>
			</div>
			<div class="col-md-6 d-flex justify-content-end align-items-center">
				<input v-model="nameQuery" type="text" class="form-control mr-2" placeholder="Name..."
							 @keyup.enter="fetchPayments">
				<input v-model="emailQuery" type="text" class="form-control mr-2" placeholder="Email..."
							 @keyup.enter="fetchPayments">
			</div>
		</div>
		<!-- / Actions -->

		<!-- Table -->
		<b-table
			ref="paymentsTable"
			class="position-relative text-nowrap mb-5"
			:items="payments"
			responsive
			:fields="tableFields"
			show-empty
			empty-text="No records found"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
		>

			<!-- Column: Full Name -->
			<template #cell(fullName)="data">
				<div class="font-weight-bold d-block text-nowrap">
					{{ data.item.fullName }}
				</div>
			</template>

			<!-- Column: Email -->
			<template #cell(email)="data">
				<div class="text-nowrap">
					<span class="align-text-top">{{ data.item.email }}</span>
				</div>
			</template>

			<!-- Column: Total Amount -->
			<template #cell(fullAmountInUSD)="data">
				<div class="text-nowrap">
					<span class="align-text-top">${{ data.item.fullAmountInUSD }}</span>
				</div>
			</template>

			<!-- Column: Payment Month -->
			<template #cell(monthNumber)="data">
				<div class="text-nowrap">
					<span class="align-text-top">{{ data.item.paymentMonthName }}</span>
				</div>
			</template>

			<!-- Column: Payment Month Part -->
			<template #cell(monthPart)="data">
				<div class="text-nowrap">
					<span class="align-text-top">{{ data.item.paymentMonthPart }}</span>
				</div>
			</template>

			<!-- Column: Payment Year -->
			<template #cell(paymentYear)="data">
				<div class="text-nowrap">
					<span class="align-text-top">{{ data.item.paymentYear }}</span>
				</div>
			</template>

			<!-- Column: Status -->
			<template #cell(paymentStatusCategory)="data">
				<div class="text-nowrap">
					<span class="align-text-top">{{ statusLabel(data.item.paymentStatusCategory) }}</span>
				</div>
			</template>

			<!-- Column: Total Bainkom -->
			<template #cell(totalBainkomInUSD)="data">
				<div class="text-nowrap">
					<span class="align-text-top text-capitalize">${{ data.item.totalBainkomInUSD }}</span>
				</div>
			</template>

			<!-- Column: Total Due -->
			<template #cell(amountInUSD)="data">
				<div class="text-nowrap">
					<span class="align-text-top text-capitalize">${{ data.item.amountInUSD }}</span>
				</div>
			</template>

			<!-- Column: Actions -->
			<template #cell(actions)="data">
				<router-link :to="{name: 'PaymentsShow', params: {professionalId: data.item.professionalId, monthNumber: data.item.paymentMonthNumber, year: data.item.paymentYear, monthPart: data.item.paymentMonthPart }}"
										 v-if="data.item.paymentStatusCategory === 'due'">
					<button class="btn btn-sm btn-primary px-2">
						Pay
					</button>
				</router-link>

				<router-link :to="{name: 'PaymentsShow', params: {professionalId: data.item.professionalId, monthNumber: data.item.paymentMonthNumber, year: data.item.paymentYear, monthPart: data.item.paymentMonthPart }}" v-else-if="(data.item.paymentStatusCategory === 'completed')">
					<button class="btn btn-sm btn-primary px-2">
						View
					</button>
				</router-link>
				<div v-else>
					<button class="btn btn-sm btn-primary px-2" disabled>
						Pay
					</button>
				</div>
			</template>
		</b-table>
		<!-- / Table -->

		<!-- Pagination -->
		<div class="row px-2">
			<div class="col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
        <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
        </span>
			</div>
			<div class="col-sm-6">
				<b-pagination
					v-model="currentPage"
					:total-rows="totalPayments"
					:per-page="perPage"
					first-number
					last-number
					prev-class="prev-item"
					next-class="next-item"
					class="d-flex align-items-center justify-content-center justify-content-sm-end"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18"/>
					</template>

					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18"/>
					</template>
				</b-pagination>
			</div>
		</div>
		<!-- / Pagination -->
	</div>
</template>

<script>
import {BPagination, BTable, BModal} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
	components: {
		vSelect,
		BTable,
		BModal,
		BPagination
	},

	data() {
		return {
			currentPage: 1,
			perPage: 100,
			perPageOptions: [10, 25, 50, 100],
			nameQuery: '',
			emailQuery: '',
			categoryQuery: null,
			tableFields: [
				{
					key: 'fullName',
					label: 'Designer',
					sortable: true
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true
				},
				{
					key: 'fullAmountInUSD',
					label: 'Total Amount',
					sortable: true
				},
				{
					key: 'monthNumber',
					label: 'Month',
					sortable: true
				},
				{
					key: 'monthPart',
					label: 'Part',
					sortable: true
				},
				{
					key: 'paymentYear',
					label: 'Year',
					sortable: true
				},
				{
					key: 'paymentStatusCategory',
					label: 'Status',
					sortable: false
				},
				{
					key: 'totalBainkomInUSD',
					label: 'Total Bainkom',
					sortable: true
				},
				{
					key: 'amountInUSD',
					label: 'Total Due',
					sortable: true
				},
				{
					key: 'actions',
					sortable: false
				}
			],
			sortBy: 'fullName',
			sortDesc: false,
			payments: [],
			totalPayments: 0
		}
	},

	computed: {
		dataMeta() {
			return {
				from: this.perPage * (this.currentPage - 1) + (this.totalPayments ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + this.totalPayments,
				of: this.totalPayments
			}
		},

		queryParams() {
			return {
				sortBy: this.sortBy,
				sortOrder: this.sortDesc ? 1 : 0,
				skip: (this.currentPage - 1) * this.perPage,
				take: this.perPage,
			}
		},
	},

	watch: {
		queryParams: {
			handler() {
				this.fetchPayments();
			},
			immediate: true
		}
	},

	methods: {
		async fetchPayments() {
			const params = this.queryParams;
			params.name = this.nameQuery;
			params.email = this.emailQuery;
			params.paymentStatusCategory = this.categoryQuery;

			const response = await this.$http.get('payments', {
				params
			});
			this.payments = response.data.results;
			this.totalPayments = response.data.count;
		},

		setCategoryQuery(categoryQuery) {
			this.categoryQuery = categoryQuery;

			this.fetchPayments();
		},

		statusLabel(status){
			const statusCapitalized = status.replace(
				/^./,
				status[0].toUpperCase()
			);

			return statusCapitalized.split("_").join(" ");
		}
	}
}
</script>

<style lang="scss" scoped>
.statuses {
	.nav-link {
		background-color: white;
	}
}
</style>
